import React from "react";
import styled from "styled-components";
import { IconUser16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { LogicalLink } from "./LogicalLink";
import { TargetBlankHelper } from "./TargetBlankHelper";

const UserLink = styled(LogicalLink)`
  ${fontFamily(ffdinPro.regular)}
  ${fontSize("14px")}
  align-items: center;
  color: ${colors.primary};
  display: inline-flex;
  text-decoration: none;
  transition: color 0.2s;
  position: relative;

  ${IconUser16} {
    margin-right: 10px;
    transition: fill 0.2s;
  }

  &:hover {
    color: ${colors.primaryDk};

    ${IconUser16} {
      fill: ${colors.primaryDk};
    }
  }
`;

export const NewUserLink = ({
  ...userLinkProps
}: {
  to?: string;
  target?: "_blank";
  href?: string;
}) => (
  <UserLink {...userLinkProps}>
    <IconUser16 fill={colors.primary} width={16} />
    Nouvel utilisateur
    <TargetBlankHelper />
  </UserLink>
);
