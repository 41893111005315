import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  left: -9000px;
  width: 0;
  overflow: hidden;
`;

export const TargetBlankHelper = () => (
  <Container>Opens a new window</Container>
);
